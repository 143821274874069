import React from "react";
import dayjs from "dayjs";
import hu from "dayjs/locale/hu";

import "./src/assets/base.css";

dayjs.locale(hu);

export const wrapRootElement = ({ element }) => {
  return <div>{element}</div>;
};
